.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}

.form-check {
    text-align: left;
} 
.form-check label {
    cursor: pointer;
}
header .bg-dark {
    background-color: #1a222b!important;
}
section.search .bg-secondary {
    background-color: #1a222b!important;
}
.btn-primary {
    color: #fff;
    background-color: #f15f28;
    border-color: #f15f28;
}
.btn-primary:hover {
    color: #fff;
    background-color: #fb9c79;
    border-color: #f15f28;
}
.card-header {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background-color: rgba(26,34,43,1);
    border-bottom: 1px solid rgba(26,34,43,.125);
    color: #fff;
}

.btn-outline-secondary {
    color: #fff;
    background-color: #f15f28;
    border-color: #fb9c79;
}
.btn-outline-secondary:hover {
    color: #fff;
    background-color: #fb9c79;
    border-color: #f15f28;
}
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #f15f28;
    border-color: #fb9c79;
}
.page-link {
    color: #f15f28;
    border-color: #fb9c79;
}
.page-item.disabled .page-link {
    border-color: #fb9c79;
}
.page-link:hover {
    z-index: 2;
    color: #fff;
    background-color: #fb9c79;
    border-color: #fb9c79;
}
.partner .card-footer .text-end small{
    font-size: 0.7rem;
    color: #bebebe;
}
a {
    color: #f15f28;
}
a:hover {
    color: #fb9c79;
}
#partnersMap {
    height: 80vh;
  }